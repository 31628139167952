body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




// loader css start

.loader-wrapper {
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 9999;
	top: 0;
	left: 0;
  }
  
  .loader-wrapper .loader {
	position: relative;
	width: 100px!important;
	height: 100px!important;
	border-radius: 50%;
	background-color: #fff;
	// box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
  }
  
  .loader-wrapper .loader:before {
	content: "";
	width:100px!important;
	height:100px!important;
	// border: 5px solid rgba(0, 0, 0, 0.05);
	border-top-color: #9f2342;
	border-radius: 50%;
	animation: spin 1.5s infinite linear;
	display: block;
	
  }
  
  
  @keyframes spin{
  
	0%{
	  transform: rotate(0deg);
	}
	100%{
	  transform: rotate(360deg);
	}
  
  }
  @-webkit-keyframes spin{
  
	0%{
	  transform: rotate(0deg);
	}
	100%{
	  transform: rotate(360deg);
	}
  
  }
  
  .loader-wrapper .loader svg {
	width: 60%;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 52px;
    fill: #9f2342;
  }

// loader css end